import { useI18n } from 'vue-i18n'

const options: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
}

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
}

export const formatDate = (date: Date | null | undefined, locale?: string): string => {
  if (!locale) locale = 'nl-NL'
  if (date instanceof Date) {
    return new Intl.DateTimeFormat(locale, options).format(date)
  }
  return ''
}

export const formatTime = (date: Date, locale?: string): string => {
  if (!locale) locale = 'nl-NL'
  return new Intl.DateTimeFormat(locale, timeOptions).format(date)
}

export const formatFullDate = (date: Date, locale?: string): string => {
  if (!locale) locale = 'nl-NL'
  return `${formatDate(date, locale)} ${formatTime(date)}`
}

export const convertToDate = (date: string): Date => {
  if (!date)
    return new Date()

  return new Date(date)
}

export const formatDateForBackend = (date: Date): string => {
  return date.toISOString().substring(0, 10)
}

export const getHumanReadableDate = (date: Date, locale?: string): string => {
  const {t} = useI18n()
  if (!locale) locale = 'nl-NL'
  if (isToday(date)) return `${t('dates.today')}, ${formatDate(date, locale)}`
  else if (isTomorrow(date)) return `${t('dates.tomorrow')}, ${formatDate(date, locale)}`
  else if (isYesterday(date)) return `${t('dates.yesterday')}, ${formatDate(date, locale)}`
  return formatDate(date, locale)
}

export const getHumanReadableDateWithTime = (date: Date): string => {
  const {t} = useI18n()
  if (isToday(date)) return `${t('dates.today')} ${t('dates.at')} ${formatTime(date)}`
  else if (isTomorrow(date)) return `${t('dates.tomorrow')} ${t('dates.at')} ${formatTime(date)}`
  else if (isYesterday(date)) return `${t('dates.yesterday')} ${t('dates.at')} ${formatTime(date)}`
  return formatTime(date)
}

export const isToday = (date: Date) => {
  return isSpecificDay(date, 0)
}

export const isTomorrow = (date: Date) => {
  return isSpecificDay(date, 1)
}

export const isYesterday = (date: Date) => {
  return isSpecificDay(date, -1)
}

export const isSpecificDay = (date: Date, offset: number = 0) => {
  const day = new Date()
  day.setDate(day.getDate() + offset)
  return date.getDate() === day.getDate() && date.getMonth() === day.getMonth() && date.getFullYear() === day.getFullYear()
}
